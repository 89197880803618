.contentContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-8);
}

.outerFrame {
  border: 1px solid var(--color-gray2);
  border-radius: var(--border-radius-lg);
  position: relative;
  padding: var(--spacing-4) var(--spacing-2);
}

.boxFrameUsp {
  border: 1px solid var(--color-gray2);
  position: absolute;
  border-radius: var(--border-radius-lg);
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  left: 0;
  right: 0;
  top: calc(-1 * var(--spacing-25));
  background: var(--color-white);
  margin: 0 auto;
  width: 200px;
  min-height: 36px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.uspText {
  margin-left: var(--spacing-15);
}

.teaserHeader {
  max-width: 254px;
}

.image {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 768px) {
  .outerFrame {
    padding: var(--spacing-4);
  }

  .boxFrameUsp {
    flex-direction: row;
  }
}
