.contentWrapper {
  composes: gridSmallWrapper from '@/global/style/Layout.module.css';
  display: block;
  z-index: 1;
  width: 100%;
  min-height: 300px;
}

.contentWrapper > section {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

/* BoxFrameUsp styles */
.boxFrameUsp {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: var(--spacing-1);
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 1px solid var(--color-gray2);
  border-radius: var(--border-radius-lg);
  background: var(--color-white);
  width: 200px;
  min-height: 48px;
}

/* InfoFrame styles */
.infoFrame {
  border: 1px solid var(--color-gray2);
  margin: 0 auto;
  border-radius: var(--border-radius-lg);
  display: flex;
  flex-direction: row;
  padding: var(--spacing-2) var(--spacing-15) var(--spacing-2) var(--spacing-3);
}

/* OuterWrapper styles */
.outerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

/* InnerWrapper styles */
.innerWrapper {
  display: flex;
  flex-direction: column;
}

.innerWrapper:last-child {
  flex: 0 0 auto;
  flex-direction: row;
  margin-top: var(--spacing-2);
  justify-content: space-between;
}

/* UspList styles */
.uspList {
  margin-bottom: var(--spacing-5);
  margin-left: var(--spacing-2);
}

/* UspListItem styles */
.uspListItem {
  position: relative;
  margin-bottom: var(--spacing-2);
  margin-left: var(--spacing-2);
  list-style: none;
}

.uspListItem::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--color-mine-shaft);
  position: absolute;
  left: -16px;
  top: 8px;
}

/* LowerWrapper styles */
.lowerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Media Queries */
@media (min-width: 480px) {
  .outerWrapper {
    flex-direction: row;
  }

  .innerWrapper:last-child {
    flex-direction: column;
    margin-top: 0;
    margin-right: var(--spacing-3);
    justify-content: center;
  }
}

@media (min-width: 640px) {
  .boxFrameUsp {
    min-height: 36px;
  }

  .innerWrapper:first-child {
    margin-right: var(--spacing-8);
  }
}

@media (min-width: 768px) {
  .contentContainer {
    padding: 0 var(--spacing-3);
  }
}

@media (min-width: 1148px) {
  .contentContainer {
    padding: 0 var(--spacing-5);
  }
}
